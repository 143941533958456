import React, { Component } from "react";
import { Link } from "react-router";
import { Row, Col } from "antd";

class Cta extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* achive-business-goal  */}
        <section className="achive-business-goal">
          <Row justify="center">
            <Col span={20}>
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>
                    Achieve business goals faster with data science and AI
                  </h2>
                  <p>How we’ve helped clients increase business value…</p>
                  <Link to="/contact" className="btn btn-primary">
                    Talk to an Expert{" "}
                    <i className="flaticon-arrow-pointing-to-right" />
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
}

export default Cta;
