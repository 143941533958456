import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import datamigration from "../../assets/images/services/data-migration.jpg";
import integrationbuild from "../../assets/images/services/integration-build.jpg";
import testing from "../../assets/images/services/testing.jpg";
import dataextratcion from "../../assets/images/services/data-extraction.jpg";
import bigData from "../../assets/images/services/big-data.jpg";
import healthcareImage from "../../assets/images/services/health-care.jpg";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Cta from "../Cta/Cta";

class Services extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum services",
                title: "Our Services",
                description:
                  "Together we can envision, inspire and transform your business.",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <div className="mcbitss-solution">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="solutions-list">
                      <div className="list">
                        <div className="details">
                          <Link to="/big-data-analytics">
                            <img
                              src={bigData}
                              alt="Data Extraction and Transformation"
                            />
                            <h4>Big Data Analytics </h4>
                            <p>
                              Unlock business value with Big data analytics,
                              Artificial Intelligence (AI), Machine Learning,
                              Predictive Analytics, and Natural Language
                              Processing (NLP)
                            </p>
                          </Link>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <Link to="/healthcare">
                            <img
                              src={healthcareImage}
                              alt="Data Extraction and Transformation"
                            />
                            <h4>Big Data in Healtcare </h4>
                            <p>
                              Healthcare analytics help make sense of these
                              massive data sets and drive not only positive but
                              also life-saving outcomes.
                            </p>
                          </Link>
                        </div>
                      </div>
                      {/* <!--List ends--> */}
                      <div className="list">
                        <div className="details">
                          <img
                            src={dataextratcion}
                            alt="Data Extraction and Transformation"
                          />
                          <h4>Data Extraction and Transformation </h4>
                          <p>
                            We have in-house build tools to extract data from
                            SAP, pre-configured rules and convert into
                            Successfactor and Workday specific Templates.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <img src={datamigration} alt="Data Migration" />
                          <h4>Data Migration </h4>
                          <p>
                            We are expert in data migration from SAP and other
                            sources to Successfactor and Workday. We have
                            knowledge of both SAP and Successfactor and Workday
                            systems.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <img src={integrationbuild} alt="Integration Build" />
                          <h4>Integration Build</h4>
                          <p>
                            We have knowledge of all frequently used 3rd party
                            systems including ADP, SDWorx etc.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <img src={testing} alt="Testing" />
                          <h4>Testing</h4>
                          <p>
                            We have in-house build tool for automated testing to
                            test workday functionalities after each patch.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Cta />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Services;
