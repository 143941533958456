import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import Aboutmcbitss from "../../assets/images/about-mcbitss.png";
import "./About.scss";
import { Row, Col, Steps } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Cta from "../Cta/Cta";

const { Step } = Steps;

class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum about-us",
                title: "About Us",
                description:
                  "We treat our culture with the same importance as our business strategy.",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}
          {/* <!--About McBitss section starts--> */}
          <div className="about-section">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <h1>About Mcbitss</h1>
                    <p>
                      McBitss was founded in 2012 to support customers achieve
                      their strategic ERP{" "}
                      <strong>(specifically SAP HR and Peoplesoft)</strong>{" "}
                      objectives and either maintain or fully optimize their ERP
                      solution long-term. Witnessing first-hand the constant
                      challenges and frustration customers faced for data
                      migration and integrations from{" "}
                      <strong>SAP to Successfactor and Workday</strong>, McBitss
                      brought in the SAP HR expertise to translate challenges to
                      solutions.{" "}
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <img
                      src={Aboutmcbitss}
                      alt="About McBitss"
                      title="About McBitss"
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <p>
                      Using extensive business management skills,{" "}
                      <strong>SAP HR and PeopleSoft expertise</strong>, initial
                      focus was to solve all problems in SAP HR and PeopleSoft
                      challenges which eventually evolved into support and
                      deliver Successfactor and Workday data migration and
                      integrations. With the solid expertise in SAP HR and
                      PeopleSoft, focus on{" "}
                      <strong>
                        Successfactor and Workday data migration and integration
                      </strong>{" "}
                      paved the way to help our customers for a smoother
                      transition from their{" "}
                      <strong>
                        legacy systems to Successfactor and Workday
                      </strong>
                      . Understanding the Data in SAP HR and PeopleSoft system
                      is our key strength to service Successfactor and Workday
                      implementation for our customers.{" "}
                    </p>
                    <p>
                      <strong>Our mission</strong> is to re-energize clients’
                      strategic vision using Successfactor and Workday and to
                      help them achieve a big ROI by using our customized
                      proprietary accelerator tools on Data migration,
                      Integration and Testing .{" "}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* <!--About McBitss section ends--> */}

          <section className="mcbitss-expertise">
            <Row justify="center">
              <Col span={20}>
                <h2>Our expertise includes</h2>

                <Row justify="center" className="mb-20">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 18 }}
                    lg={{ span: 18 }}
                  >
                    <Row justify="center" gutter={16}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <div className="image">
                          <img
                            src={require("../../assets/images/about/digital-hr-services.png")}
                            alt="Digital HR Services"
                          />
                        </div>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <div className="details">
                          <h3>Digital HR Services</h3>
                          <p>
                            Mcbitss specializes in helping customers implement,
                            extend and support <strong>SAP HCM systems</strong>,
                            Peoplesoft and Successfactor and Workday solutions
                            as part of a digital HR transformation process.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row justify="center" className="mb-20">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 18 }}
                    lg={{ span: 18 }}
                  >
                    <Row justify="center" gutter={16}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ sspan: 12, push: 12 }}
                        lg={{ span: 12, push: 12 }}
                      >
                        <div className="image">
                          <img
                            src={require("../../assets/images/about/data-migration.png")}
                            alt="Digital HR Services"
                          />
                        </div>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12, pull: 12 }}
                        lg={{ span: 12, pull: 12 }}
                      >
                        <div className="details">
                          <h3>HR Data Migration & Integration</h3>
                          <p>
                            Mcbitss provides expertise to help customers migrate
                            data from legacy HR systems, databases or disparate
                            files to Successfactor and Workday. We also have
                            significant experience integrating cloud HR
                            solutions with other HRIS, ERP, benefits and payroll
                            providers.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row justify="center" className="mb-20">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 18 }}
                    lg={{ span: 18 }}
                  >
                    <Row justify="center" gutter={16}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <div className="image">
                          <img
                            src={require("../../assets/images/about/Digital-HR-Strategy.png")}
                            alt="Digital HR Services"
                          />
                        </div>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <div className="details">
                          <h3>Digital HR Strategy, Design & Execution</h3>
                          <p>
                            Mcbitss works closely with its customers to help
                            them plan their digital HR strategy and determine
                            how to best consume the latest HR technology like
                            analytics, mobile solutions, employee referral
                            programs, and more.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          <section className="our-story circle-shape">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={20}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 16 }}
                    lg={{ span: 16 }}
                  >
                    <div className="details">
                      <h2>Our Story</h2>
                      <p>
                        Mcbitss is a SAP HCM Partner, driving{" "}
                        <strong>Digital HR solution</strong> across Advisory,
                        Implementation, Integration and Migration from
                        On-Premise to the Cloud. We focus on{" "}
                        <strong>HR technology</strong> with a wide range of HR
                        processes: from HR Foundation processes (core HR,
                        Payroll, Time, Benefits), across Talent Management, to
                        provide the foundation for Digital HR solutions.
                      </p>
                      <p>
                        We assist our clients in all aspects of their projects,
                        from feasibility study over the technical integration to
                        outsourcing.
                      </p>
                      <p>
                        Today, Mcbitss provides services all over World with
                        offices in Switzerland, USA, Germany, Singapore, India,
                        Poland, Romania, Canada, Czech Republic, and UAE.{" "}
                      </p>
                      <p>
                        Mcbitss counts{" "}
                        <strong>50+ Successfactor and Workday</strong>, SAP &
                        Peoplesoft HR experts, who deliver innovative and high
                        quality services to our customers around the world. Our
                        core values have shaped the culture and defined the
                        character of our company. At Mcbitss, we think that the
                        commitment, the effectiveness, the quality, the
                        proximity and the pragmatism demonstrated by our
                        consultants are key success factors to help our
                        customers improve their performance.
                      </p>
                      <p>
                        In the coming years, Mcbitss will expand its presence
                        across World, with a strong focus on the new generation
                        of{" "}
                        <strong>
                          Digital HR (Analytics, Cloud services, Artificial
                          Intelligence)
                        </strong>
                        .{" "}
                      </p>
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="our-values">
                      <h2>Our Values</h2>
                      <ul>
                        <li>
                          To be{" "}
                          <strong>professional in our presentation</strong>,
                          attitude and respect to commitment.
                        </li>
                        <li>
                          To work closely with our clients in the research and
                          quest for <strong>sustainable solutions</strong>.
                        </li>
                        <li>
                          To demonstrate our quality and effectiveness through
                          our commitment to results.
                        </li>
                        <li>
                          To promote the development of skills by adopting a{" "}
                          <strong>culture of knowledge transfer</strong>, both
                          within our organization and with our customers.
                        </li>
                        <li>
                          To encourage a spirit of cooperation within our teams.
                        </li>
                        <li>
                          To be closely involved with our customers in order to
                          fully understand their{" "}
                          <strong>constraints and objectives</strong>.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          <Cta />
        </Layout>
      </React.Fragment>
    );
  }
}

export default About;
