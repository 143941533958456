import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import Layout from "../Layout";
import { Row, Col } from "antd";
import { knowmoreblog } from "../../actions/blogactions";
// import TBD from '../../assets/images/it-solutions/blockchain.svg';

class Solutions extends React.Component {
  knowmoreaboutblockchain = (blog) => {
    this.props.router.push("/blockchain-and-internet-of-things");
    this.props.dispatch(knowmoreblog(blog));
  };

  knowmoreaboutmachinelearning = (blog) => {
    this.props.router.push("/machine-learning-and-predictive-analysis");
    this.props.dispatch(knowmoreblog(blog));
  };
  knowmoreaboutInformationSecurity = (blog) => {
    this.props.router.push("/information-security");
    this.props.dispatch(knowmoreblog(blog));
  };
  knowmoreaboutWorkday = (blog) => {
    this.props.router.push("/workday");
    this.props.dispatch(knowmoreblog(blog));
  };
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <div className="breadcrum solutions">
            <div className="breadcrum-content">
              <h1>Our Solutions</h1>
              <span>
                <Link to="/">Home</Link> <em className="seperator">|</em> Our
                Solutionss
              </span>
            </div>
          </div>
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <div className="mcbitss-solution">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="solutions-list">
                      <div className="list">
                        <div className="details">
                          <Link to="/blockchain-and-internet-of-things">
                            {/* <img src={TBD} alt="TBD" /> */}

                            <h4>TBD</h4>
                          </Link>
                          <div className="hovercontent">
                            <div className="cont">
                              <p>
                                Your quest to find a Blockchain Solutions
                                Company ends here!
                              </p>
                              <div className="left">
                                <button
                                  onClick={() =>
                                    this.knowmoreaboutblockchain({
                                      category:
                                        "Blockchain And Internet Of Things",
                                    })
                                  }
                                >
                                  Know More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});
// export default Solutions;

export default connect(mapStateToProps)(Solutions);
