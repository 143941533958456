import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import Select from "react-select";
import { Table, Row, Col, Menu, Modal, Button, Dropdown } from 'antd';
import CheckBoxTable from "./checkboxtable";
import { DownOutlined } from '@ant-design/icons';
import { getContactus } from '../actions/contactusactions';
import moment from 'moment'

class ContactDetails extends React.Component {

    // model popup starts
    state = {
        loading: false,
        visible: false,
        contactusArr: [],
        selectedContactUs: false
    };

    componentDidMount() {
        getContactus().then((data) => {
            if (data && !data.error) {
                this.setState({ contactusArr: data.result })
            }
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({ selectedContactUs: false });
    };
    //   model popup ends here

    render() {
        const { selectedContactUs } = this.state;
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortDirections: ['descend'],
            },
            {
                title: 'Email ID',
                dataIndex: 'email',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.email - b.email,
            },
            {
                title: 'Phone Number',
                dataIndex: 'phone',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.phone - b.phone,
            },
            {
                title: 'Subject',
                dataIndex: 'subject',
            },
            {
                title: 'Date & Time',
                dataIndex: 'createdAt',
                render: (val) => val ? moment(val).format('DD-MMM-YYYY') : ''
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'x',
                render: (val, row) =>
                    <a className="ant-dropdown-link" onClick={() => this.setState({ selectedContactUs: row })}>
                        View
                    </a>,
            },
        ];
        return (
            <div>
                <h1>Contact Details</h1>

                {/* <div className="action-button">
                    <Button type="primary" className="btn btn-primary">
                        Export
                    </Button>
                </div> */}

                <div className="card-design">
                    <Table columns={columns} dataSource={this.state.contactusArr} />
                </div>

                {/* model for view details */}
                <Modal
                    visible={selectedContactUs ? true : false}
                    title="View Details"
                    onCancel={this.handleCancel}
                    width={800}
                    footer={false}
                >
                    <div className="view-contact-details">
                        <Row justify="center" gutter={16}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="form-felds">
                                    <span>Name</span>
                                    <p>{selectedContactUs.name}</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="form-felds">
                                    <span>Email</span>
                                    <p>{selectedContactUs.email}</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="form-felds">
                                    <span>Phone Number</span>
                                    <p>{selectedContactUs.phone}</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="form-felds">
                                    <span>Subject</span>
                                    <p>{selectedContactUs.subject}</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div className="form-felds">
                                    <span>Message</span>
                                    <p>{selectedContactUs.message}</p>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Modal>
                {/* model for view details ends here */}
            </div>
        );
    }
}
const mapStateToProps = state => ({

});
export default connect(mapStateToProps)(ContactDetails);
