import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import Layout from "../Layout";
import contact from "../../assets/images/contact-us.png";
import locations from "../../assets/images/locations.png";
import { Row, Col, notification } from "antd";
import { createContactus } from "../../actions/contactusactions";
import ReCAPTCHA from "react-google-recaptcha";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";

// google recaptcha
function onChange(value) {
  console.log("Captcha value:", value);
}
// google recaptcha

class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    captcha: false,
  };

  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  onChangeCaptcha = (value) => {
    this.setState({ captcha: true });
  };

  onClear = () => {
    this.setState({ name: "", email: "", phone: "", subject: "", message: "" });
  };

  onSubmit = () => {
    const { name, email, phone, subject, message, captcha } = this.state;

    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      subject !== "" &&
      message !== ""
    ) {
      if (!captcha) {
        alert("Please confirm your not a robot.");
        return true;
      }
      createContactus({ name, email, phone, subject, message }).then((data) => {
        if (data && !data.error) {
          this.onClear();
          notification.open({
            message: "Success",
            description: "Thank you! we will reach you shortly",
          });
        } else {
          alert("Contact us failed! Please try again");
        }
      });
    } else {
      alert("Please fill all the fields");
    }
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum contact-us",
                title: "Contact Us",
                description:
                  "Thank you for your interest. Please fill out the form below to inquire about our work ",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--contact-us-page starts--> */}
          <div className="contact-us-page">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="heading">
                      <h3>We'd Love To Hear from you</h3>
                      <p>
                        Whether you have a question about features, trials,
                        pricing, need a demo, or anything, our team is ready to
                        answer all your questions
                      </p>
                    </div>

                    <Row justify="center" gutter={16}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <img
                          src={contact}
                          alt="contact McBitss"
                          title="contact McBitss"
                        />
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <div className="form-fields">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            placeholder="Full Name"
                            value={this.state.name}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "name")
                            }
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            required
                            placeholder="Email ID"
                            value={this.state.email}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "email")
                            }
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            required
                            placeholder="Phone Number"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "phone")
                            }
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            required
                            placeholder="Subject"
                            value={this.state.subject}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "subject")
                            }
                          />
                        </div>
                        <div className="form-fields">
                          <textarea
                            id="message"
                            required
                            placeholder="Message..."
                            value={this.state.message}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "message")
                            }
                          ></textarea>
                        </div>
                        <div className="form-fields">
                          <ReCAPTCHA
                            sitekey="6Ld2BoQaAAAAAGRhgxfUZeIXZUj9ubT9Q6734N9K"
                            // sitekey="6LcslYQaAAAAAHJYwRIpQ7q-7uVi26aOK10PYsGn" localhost for testing
                            onChange={this.onChangeCaptcha}
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            className="btn btn-primary"
                            type="button"
                            value="Submit"
                            onClick={this.onSubmit}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* <!--contact-us-page ends--> */}

          {/* <!--locations starts--> */}
          <div className="locations">
            <div className="container">
              <h2>Office Locations</h2>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <img src={locations} alt="Mcbitss locations" />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default connect()(Contact);
