import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

class Sidemenubar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  addblog = () => {
    this.props.router.push('/addblog');
  }
  addFaq = () => {
    this.props.router.push('/addfaq');
  }
  allfaqs = () => {
    this.props.router.push('/allfaqs');
  }
  allblogs = () => {
    this.props.router.push('/allblogs');
  }
  requestdemos = () => {
    this.props.router.push('/requestdemos');
  }
  contactdetails = () => {
    this.props.router.push('/contactdetails');
  }
  testimonials = () => {
    this.props.router.push('/testimonials');
  }
  render() {
    return (
      <div>
        <SideNav
          onSelect={selected => {
            // Add your code here
          }}
        >
          <SideNav.Toggle />
          <SideNav.Nav defaultSelected="blogs">
            {/* <NavItem eventKey="home">
              <NavIcon>
                <i
                  className="fas fa-th"
                  style={{ fontSize: "1.75em" }}
                />
              </NavIcon>
              <NavText>Blogs</NavText>
              <NavItem eventKey="blogs">
                <NavText onClick ={this.addblog} >Add Blog</NavText>
              </NavItem>
              <NavItem eventKey="charts/barchart">
                <NavText onClick ={this.allblogs}>All Blogs</NavText>
              </NavItem>
            </NavItem> */}
            {/* <NavItem eventKey="FAQS">
              <NavIcon>
                <i
                  className="fas fa-question"
                  style={{ fontSize: "1.75em" }}
                  onClick ={this.allfaqs}
                />
              </NavIcon>
              <NavText onClick ={this.allfaqs}>FAQS</NavText>
            </NavItem> */}
            {/* <NavItem eventKey="Request Demos">
              <NavIcon>
                <i
                  className="fas fa-question"
                  style={{ fontSize: "1.75em" }}
                  onClick ={this.requestdemos}
                />
              </NavIcon>
              <NavText onClick ={this.requestdemos}>Request Demos</NavText>
            </NavItem> */}
            <NavItem eventKey="Requestdemos">
              <NavIcon>
                <i
                  className="fas fa-laptop"
                  style={{ fontSize: "1.75em" }}
                  onClick ={this.requestdemos}
                />
              </NavIcon>
              <NavText onClick ={this.requestdemos}>Request Demos</NavText>
            </NavItem>      
            <NavItem eventKey="contactdetails">
              <NavIcon>
                <i
                  className="fas fa-phone"
                  style={{ fontSize: "1.75em" }}
                  onClick ={this.contactdetails}
                />
              </NavIcon>
              <NavText onClick ={this.contactdetails}>Contact Reports</NavText>
            </NavItem>    
            <NavItem eventKey="testimonials">
              <NavIcon>
                <i
                  className="fa fa-comments"
                  style={{ fontSize: "1.75em" }}
                  onClick ={this.testimonials}
                />
              </NavIcon>
              <NavText onClick ={this.testimonials}>Testimonials</NavText>
            </NavItem>         
          </SideNav.Nav>
        </SideNav>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  });
  
  const enhancedComponent = connect(mapStateToProps)(withRouter(Sidemenubar));

  
  export default enhancedComponent;