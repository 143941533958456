import React, { Component } from "react";
import { Router, Route, hashHistory, IndexRoute } from "react-router";
import Home from "./Home/Home";
import Layout from "./Layout";
import About from "./About/About";
import Tools from "./Tools/Tools";
import StakeHolders from "./StakeHolders/StakeHolders";
import Contact from "./Contact/Contact";
import ReorgchartModelling from "./ReorgchartModelling/ReorgchartModelling";
import Dc3 from "./Dc3/Dc3";
import AksaStudio from "./AksaStudio/AksaStudio";
import Services from "./Services/Services";
import Solutions from "./Solutions/Solutions";
import PrivacyPolicy from "./Privacy/PrivacyPolicy";
import Disclaimer from "./Disclaimer/Disclaimer";
import BigDataAnalytics from "./BigDataAnalytics/BigDataAnalytics";
import HealthCare from "./HealthCare/HealthCare";
import Career from "./Career/Career";
import QaAutomationLead from "./Jobs/QaAutomationLead";
import ItBusinessAnalyst from "./Jobs/ItBusinessAnalyst";
import JavaDevelopers from "./Jobs/JavaDevelopers";
import FrontEndDeveloper from "./Jobs/FrontEndDeveloper";
// import CaseStudy from './CaseStudy';
// import Blog from './Blog';
// import BlogDetails from './BlogDetails';
import Faq from "./Faq/Faq";
import Login from "../Login";
import adminlogin from "./Adminlogin";
import webAdminPage from "./webadminpage";
import Blogs from "./Blogs";
import Allblogs from "./Allblogs";
import AddFAQS from "./AddFAQS";
import AllFAQS from "./AllFaqs";
import requestdemos from "./requestdemos";
import ContactDetails from "./ContactDetails";
import AddTestimonials from "./AddTestimonials.js";
class Routes extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router history={hashHistory}>
          <Route path="/about" component={About} />
          <Route path="/stakeholders" component={StakeHolders} />
          <Route path="/contact" component={Contact} />
          <Route
            path="/reorg-chart-modelling"
            component={ReorgchartModelling}
          />
          <Route path="/dc3" component={Dc3} />
          <Route path="/aksa-studio" component={AksaStudio} />
          <Route path="/services" component={Services} />
          <Route path="/faq" component={Faq} />
          <Route path="/solutions" component={Solutions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/tools" component={Tools} />
          <Route path="/big-data-analytics" component={BigDataAnalytics} />
          <Route path="/healthcare" component={HealthCare} />
          <Route path="/career" component={Career} />
          <Route path="/qa-automation-lead" component={QaAutomationLead} />
          <Route path="/it-business-analyst" component={ItBusinessAnalyst} />
          <Route path="/java-developers" component={JavaDevelopers} />
          <Route path="/front-end-developer" component={FrontEndDeveloper} />

          {/* <Route path="/case-study" component={CaseStudy} />
                        <Route path="/blog" component={Blog} />
                        <Route path="/blog-details" component={BlogDetails} /> */}
          <Route path="/webadminlogin" component={Login} />
          <Route path="/adminlogin" component={adminlogin} />
          <Route path="/webadmin" component={webAdminPage} />

          <Route path="/" component={webAdminPage}>
            <IndexRoute component={Home} />
            <Route path="/addblog" component={Blogs} />
            <Route path="/allblogs" component={Allblogs} />
            <Route path="/addfaq" component={AddFAQS} />
            <Route path="/allfaqs" component={AllFAQS} />
            <Route path="/requestdemos" component={requestdemos} />
            <Route path="/contactdetails" component={ContactDetails} />
            <Route path="/testimonials" component={AddTestimonials} />
          </Route>
        </Router>
      </React.Fragment>
    );
  }
}

export default Routes;
