import React, { Component } from "react";
import { Link } from "react-router";
import { Row, Col, BackTop } from "antd";
import "./Footer.scss";

const style = {
  height: 40,
  width: 40,
  lineHeight: "40px",
  borderRadius: 4,
  backgroundColor: "#1088e9",
  color: "#fff",
  textAlign: "center",
  fontSize: 14,
  zIndex: 99,
  right: 36,
};
class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="footer">
          <Row justify="center">
            <Col span={20}>
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <div className="links">
                    <h4>Helpful Links</h4>
                    <ul>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/career">Career</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/stakeholders">Stakeholders</Link>
                      </li>
                      <li>
                        <Link to="/big-data-analytics">Big Data Analytics</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                {/* <!--Helpful Links ends--> */}

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <div className="links">
                    <h4>Informations</h4>
                    <ul>
                      <li>
                        <Link to="#">Team</Link>
                      </li>
                      <li>
                        <Link to="#">Partners</Link>
                      </li>
                      <li>
                        <Link to="#">Testimonials</Link>
                      </li>
                      <li>
                        <Link to="#">Customers</Link>
                      </li>
                      {/* <li><Link to="/faq">FAQ</Link></li> */}
                    </ul>
                  </div>
                </Col>
                {/* <!--Support--> */}

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <div className="links">
                    <h4>Support</h4>
                    <ul>
                      <li>
                        <Link to="/disclaimer">Disclaimer</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/webadminlogin">Web Admin Login</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      {/* <li><Link to="/faq">FAQ</Link></li> */}
                    </ul>
                  </div>
                </Col>
                {/* <!--Support--> */}

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <div className="footer-cta">
                    <div className="cta-details">
                      <h4>
                        <a href="tel:+41415613284">+41 415613284</a>
                      </h4>
                      <p>Mon - Fri 9Am - 6Pm</p>
                    </div>
                    <div className="cta-details">
                      <h4>
                        <a href="mailto:info@mcbitss.com">info@mcbitss.com</a>
                      </h4>
                      <p>Online Support</p>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="bottom-footer">
                <div className="copyright-text">
                  <Row justify="center" gutter={16}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <div className="social-icon">
                        <a
                          href="https://www.linkedin.com/company/mcbitss-gmbh"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </a>
                        <a
                          href="https://www.facebook.com/pages/McBitss-GMBH/102437170516573"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                        <a
                          href="https://twitter.com/Mcbitss_GMBH"
                          target="_balnk"
                        >
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <p>
                        Copyright © 2021{" "}
                        <a href="https://www.mcbitss.com">McBitss</a>. All
                        Rights Reserved{" "}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <BackTop>
          <div style={style}>
            <i className="fa fa-chevron-up" />
          </div>
        </BackTop>
      </React.Fragment>
    );
  }
}

export default Footer;
