import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import datamigration from "../../assets/images/services/data-migration.jpg";
import integrationbuild from "../../assets/images/services/integration-build.jpg";
import testing from "../../assets/images/services/testing.jpg";
import dataextratcion from "../../assets/images/services/data-extraction.jpg";
import bigData from "../../assets/images/services/big-data.jpg";
import healthcareImage from "../../assets/images/services/health-care.jpg";
import JobTitle from "../../Components/JobTitle/JobTitle";

class JavaDevelopers extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <JobTitle
            {...this.props}
            jobdetails={[
              {
                title: "Java Developers (Junior & Senior)",
                location: "Zurich",
                salary: "120,000 to 140,000 CHF per annum",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <div className="job-descriptions">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 18 }}
              >
                <h2>
                  We are looking for Senior & Junior Java Developers to work on
                  the projects in Banking industry.
                </h2>
                <p>
                  An interesting position in an Agile development team,
                  designing and implementing features on the strategic
                  architecture. Collaboration with Products Owner (PO) to
                  understand user stories and provide sound technical solutions
                  as well as to understand dependencies and acceptance criteria.
                  Become part of an exciting, growing project!
                </p>
                <div className="profile-data">
                  <h3>Your profile</h3>
                  <ul>
                    <li>Expertise in Java Enterprise application </li>
                    <li>
                      Expertise in software development and production support
                    </li>
                    <li>
                      Deep knowledge and experience as full stack developer with
                      higher concentration towards middle tier skills
                    </li>
                    <li>
                      Understanding and experience in Oracle SQL, Test
                      development.
                    </li>
                    <li>
                      Working knowledge of JMS with experience in MQ series,
                      Typescript and experience with continuous integration
                      (GIT, Maven)
                    </li>
                    <li>
                      Deep understanding of Core Java 7 (or above) and working
                      knowledge of SQL, Spring Framework & Spring-boot and Scrum
                      Agile development
                    </li>
                    <li>Fluent in English. German or French an added value</li>
                  </ul>
                  <h3>
                    The ideal candidates should have experience and/or have
                    completed coursework.
                  </h3>
                  <ul>
                    <li>
                      Experience in data formats such as XML, CSV, and JSON.{" "}
                    </li>
                    <li>
                      Experience in integration technologies such as HTTP, JMS,
                      JDBC, REST, and SOAP.
                    </li>
                    <li>Experience in Core Java, Java Script, JWM, HTML</li>
                    <li>
                      Experience in Unix-scripts, Spring, Angular, jUnit,
                      SpringBoot, Maven, Hibernate
                    </li>
                    <li>
                      Experience with PL/SQL, knowledge on SQL relational
                      databases like Oracle and/or SQL server
                    </li>
                    <li>
                      Experience with Test automation - acceptance/functional
                      testing, TDD, BDD
                    </li>
                    <li>Banking knowledge is a plus</li>
                    <li>Scum methodology of work is a plus</li>
                  </ul>
                </div>
                <h4>
                  Send your CVs to{" "}
                  <a href="mailto:aline.smith@mcbitss.com">
                    aline.smith@mcbitss.com
                  </a>
                </h4>
              </Col>
            </Row>
          </div>

          {/* achive-business-goal  */}
          <div className="achive-business-goal">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 18 }}
              >
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <h2>Make the best move to choose your career</h2>
                    <p>find more jobs which suits for you..</p>
                    <Link to="/career" className="btn btn-primary">
                      More Jobs{" "}
                      <i className="flaticon-arrow-pointing-to-right" />
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default JavaDevelopers;
