import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import datamigration from "../../assets/images/services/data-migration.jpg";
import integrationbuild from "../../assets/images/services/integration-build.jpg";
import testing from "../../assets/images/services/testing.jpg";
import dataextratcion from "../../assets/images/services/data-extraction.jpg";
import bigData from "../../assets/images/services/big-data.jpg";
import healthcareImage from "../../assets/images/services/health-care.jpg";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";

class Career extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum career",
                title: "Career",
                description: "Think outside the box evolving your career.",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <div className="career-job-list">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="career-list">
                      <div className="list">
                        <div className="details">
                          <h4>QA Automation Lead (Junior & Seniors) </h4>
                          <p>
                            We are looking for Senior & Junior Automation
                            Engineers to work on the projects.
                          </p>
                          <Link to="/qa-automation-lead" className="btn">
                            More Details{" "}
                            <i className="flaticon-arrow-pointing-to-right" />
                          </Link>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <h4>IT Business Analyst (Junior and Senior) </h4>
                          <p>
                            We are looking for Senior & Junior Automation
                            Engineers to work on the projects in Banking
                            industry.
                          </p>
                          <Link to="/it-business-analyst" className="btn">
                            More Details{" "}
                            <i className="flaticon-arrow-pointing-to-right" />
                          </Link>
                        </div>
                      </div>
                      {/* <!--List ends--> */}
                      <div className="list">
                        <div className="details">
                          <h4>Java Developers (Junior & Senior) </h4>
                          <p>
                            We are looking for Senior & Junior Java Developers
                            to work on the projects in Banking industry.
                          </p>
                          <Link to="/java-developers" className="btn">
                            More Details{" "}
                            <i className="flaticon-arrow-pointing-to-right" />
                          </Link>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <h4>Front end Developer (Junior and Senior)</h4>
                          <p>
                            We are looking for Senior & Junior Front-end
                            Developers with specialisation in Angular JS to work
                            on the projects in Banking industry.
                          </p>
                          <Link to="/front-end-developer" className="btn">
                            More Details{" "}
                            <i className="flaticon-arrow-pointing-to-right" />
                          </Link>
                        </div>
                      </div>
                      {/* <!--List ends--> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          {/* achive-business-goal  */}
          <div className="achive-business-goal">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <h2>
                      Achieve business goals faster with data science and AI
                    </h2>
                    <p>How we’ve helped clients increase business value…</p>
                    <Link to="/contact" className="btn btn-primary">
                      Talk to an Expert{" "}
                      <i className="flaticon-arrow-pointing-to-right" />
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default Career;
