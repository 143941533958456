import React, { Component } from "react";
import { values } from 'lodash';
import renderHTML from 'react-render-html';
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { Link } from "react-router";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";
import Layout from "../Layout";
import { Row, Col } from 'antd';
import "react-accessible-accordion/dist/fancy-example.css";
import { fetchfaqs } from "../../actions/faqactions";

// import "bootstrap/less/bootstrap.less";

const convertHtmlToText = htmlText => {
  const enteredText = renderHTML(htmlText);
  if (enteredText.props) {
    if (enteredText.props.children[0].type === 'span') {
      return enteredText.props.children[0].props.children[0];
    }
    return enteredText.props.children[0];
  }
  return htmlText;
};

const filterDataOnSearch = (searchString, processData) =>
  processData.filter(({ updatedAt, createdAt, id, ...rest }) =>
    values(rest)
      .join('')
      .toLowerCase()
      .includes(searchString)
  );

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: '',
      activePage: 3
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchfaqs())
  }

  searchtext = (e) => {
    this.setState({ searchtext: e.target.value })
  }

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }



  render() {
    const { Faqs } = this.props;
    const { searchtext } = this.state;
    const filteredfaq = filterDataOnSearch(searchtext, Faqs);
    const filteredfaqs = searchtext === '' ? Faqs : filteredfaq;
    const props = { ...this.props };
    return (
      <React.Fragment>
        <Layout>

          {/* <!--Main Slider section starts--> */}
          <div className="breadcrum faq">
            <div className="breadcrum-content">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1>Faq</h1>
                    <span>
                      <Link to="/">Home</Link>
                      <em className="seperator">|</em> Faq
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Main Slider section ends--> */}

          {/* Mcbitss accordian starts here */}

          <div className="mcbitss-accordian freequent-question-page">
            <Row justify="center">
              <Col span={20}>
                {/* search starts here */}

                <h2>Frequently Asked Questions</h2>
                <Row justify="center" gutter={16}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <div className="faq-search">
                      <form>
                        <div className="form-fields">
                          <input type="text" value={this.state.searchtext} onChange={this.searchtext} Placeholder="Type here to search FAQ" />
                          <button ><i className="fa fa-search" /></button>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
                {/* search ends here */}

                <Row justify="center" gutter={16}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }}>
                    <div className="accordion-container">
                      <Accordion>
                        {filteredfaqs && filteredfaqs.map(faq => (
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                {faq.question}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>{renderHTML(faq.description)}</p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </Col>
                </Row>

                <Row justify="center" gutter={16}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="pagination-section">
                      <Pagination
                        activePage={this.state.activePage}
                        totalItemsCount={filteredfaqs.length}
                        itemsCountPerPage={10}
                        pageRangeDisplayed={5}
                        onChange={() => this.handlePageChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          {/* Mcbitss accordian ends here */}

        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  Faqs: state.FaqReducer.faqs
});

export default connect(mapStateToProps)(Faq);
