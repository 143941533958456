import React, { Component } from "react";
import { Link, withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";

const Mainslidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  fade: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 700,
      settings: {
        arrows: false,
      }
    }
  ]
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="mcbitss-banner">
          <div className="mcbitss-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <div className="item bigdata-analytics">
                  <Row justify="center">
                    <Col span={20}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <span>McBitss</span> */}
                            <h2>
                              Big data Analytics, Data Science and AI Services
                            </h2>
                            <p>
                              Unlock business value with Big data analytics,
                              Artificial Intelligence (AI), Machine Learning,
                              Predictive Analytics, and Natural Language
                              Processing (NLP)
                            </p>
                            <div className="action-buttons">
                              <Link to="/contact" className="btn btn-primary">
                                Contact Us
                              </Link>
                              <Link
                                to="/big-data-analytics"
                                className="btn btn-secodnary"
                              >
                                Know More{" "}
                                <i className="flaticon-arrow-pointing-to-right" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item sap-to-workday">
                  <Row justify="center">
                    <Col span={20}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <span>McBitss</span> */}
                            <h2>SAP to Successfactor and Workday</h2>
                            <ul>
                              <li>
                                Several years of experience in SAP and
                                Successfactor and Workday Systems
                              </li>
                              <li>
                                Expertise in migration from SAP to Successfactor
                                and Workday
                              </li>
                              <li>
                                In Depth of Knowledge of major 3rd party
                                integrations
                              </li>
                              <li>
                                Payroll interfaces with major leaders like ADP,
                                SD Worx
                              </li>
                            </ul>
                            <div className="action-buttons">
                              <Link to="/contact" className="btn btn-primary">
                                Contact Us
                              </Link>
                              <Link to="/about" className="btn btn-secodnary">
                                Know More{" "}
                                <i className="flaticon-arrow-pointing-to-right" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item data-migration">
                  <Row justify="center">
                    <Col span={20}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <span>McBitss</span> */}
                            <h2>
                              Expertise in data migration for Successfactor and
                              Workday
                            </h2>
                            <ul>
                              <li>10 millions of records</li>
                              <li>Master Data Management</li>
                              <li>Data Extraction, and Transformation</li>
                              <li>Data Migration</li>
                              <li>Automated Testing</li>
                              <li>Integration Design and Build</li>
                            </ul>
                            <div className="action-buttons">
                              <Link to="/contact" className="btn btn-primary">
                                Contact Us
                              </Link>
                              <Link to="/about" className="btn btn-secodnary">
                                Know More{" "}
                                <i className="flaticon-arrow-pointing-to-right" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item certified-sap-partner">
                  <Row justify="center">
                    <Col span={20}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <span>McBitss</span> */}
                            <h2>Certified SAP Partners</h2>
                            <ul>
                              <li>Silver partner with SAP</li>
                              <li>
                                Several years of experience in SAP
                                Implementation
                              </li>
                              <li>Experience of managing SAP projects</li>
                            </ul>
                            <div className="action-buttons">
                              <Link to="/contact" className="btn btn-primary">
                                Contact Us
                              </Link>
                              <Link to="/about" className="btn btn-secodnary">
                                Know More{" "}
                                <i className="flaticon-arrow-pointing-to-right " />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
