import React, { Component } from "react";
import { Link } from "react-router";
import Slider from "react-slick";
import Footer from "../../Screens/Footers/Footers";
import Header from "../Headers/Header";
import HomeSlider from "./HomeSlider/HomeSlider";
import saptoworkday from "../../assets/images/Challenges-in-migration-from-SAP-to-Workday.png";
import "./Home.scss";
import DC3 from "../../assets/images/products-inner/dc3.svg";
import aksastudio from "../../assets/images/products-inner/aksa.svg";
import reorg from "../../assets/images/products-inner/reorg-modeling.svg";
import { Row, Col } from "antd";
import Layout from "../Layout";

const testimonalsettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  autoplaySpeed: 5000,
  slidesToScroll: 2,
  autoplay: true,
  margin: 10,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

class Home extends React.Component {
  // componentDidMount() {
  //   document.getElementById("scroll-position").scrollIntoView();
  // }
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <span id="scroll-position"></span> */}
          <HomeSlider />

          <section className="challenges-integration">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <h1>
                      Challenges in migration from <br />
                      <span>SAP to Successfactor and Workday</span>
                    </h1>
                    <p>
                      For companies whose technology landscape includes both SAP
                      and Successfactor and Workday, data migration and
                      integration between the solutions can be a challenge
                      because it requires a unique understanding of the data
                      structures and requirements of both systems. Proper
                      integration is the key to maximizing the effectiveness of
                      investments in both SAP and Successfactor and Workday
                      going forward. As many customers often discover too late,
                      it can be difficult to find the right resources to manage
                      data integration processes.
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <img
                      src={saptoworkday}
                      alt="Challenges in migration from SAP to Successfactor and Workday"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          {/* mcbitss-expertise starts */}
          <section className="our-expertise">
            <div className="mcbitss-expertise"> </div>

            <Row justify="center">
              <Col span={20}>
                <div className="content">
                  <div className="center-content">
                    <h2>
                      McBitss Expertise from SAP to Successfactor and Workday{" "}
                    </h2>
                    <p>
                      McBitss has several years of experience in migration from
                      SAP to Successfactor and Workday with big pharma and
                      manufacturing companies around the world.
                    </p>
                    <p>
                      McBitss has proven tools, processes, and expertise in both
                      SAP and Successfactor and Workday to ensure a smooth
                      migration and ongoing effective integration.{" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
          {/* mcbitss-expertise ends here */}

          {/* workday-integration starts */}
          <section className="workday-integration">
            <Row justify="center">
              <Col span={20}>
                <h2>
                  Our SAP to Successfactor and Workday integration suite is
                </h2>
                <Row justify="left" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <i className="flaticon-open-sign" />
                      <h4>Open & cooperative</h4>
                      <p>
                        Compatible with any Successfactor and Workday
                        implementation partner of choice
                      </p>
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <i className="flaticon-business-strategy" />
                      <h4>End-to-end</h4>
                      <p>
                        Covers the entire lifecycle from early stage analysis to
                        post-go live, through a comprehensive Data Governance,
                        Data Migration methodology, in-house tools and automated
                        testing tools.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <i className="flaticon-analytics" />
                      <h4>Proven</h4>
                      <p>
                        We have referenceable clients and a proven track record
                        of data migration & integration projects
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <i className="flaticon-web-browser" />
                      <h4>Fully packaged offering</h4>
                      <p>
                        The full integration toolset includes SAP HCM (PY, OM,
                        PA, T&A), Peoplesoft (EC, ECP, TM), CRM and FI/CO.
                        Services and support are included
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
          {/* workday-integration ends here */}

          <section className="tools-section">
            <Row justify="center">
              <Col span={20}>
                <h2>Our Tools</h2>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="title-description">
                        <h3>Dc3</h3>
                        <p>
                          Collects data from any data source, cleanse with
                          approval workflow and consolidates the data to any
                          target system. This tool is pre-configured with
                          Workday templates.
                        </p>
                        <Link to="/dc3" className="btn">
                          More Details{" "}
                          <i className="flaticon-arrow-pointing-to-right" />
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="title-description">
                        <h3>AKSA Studio</h3>
                        <p>
                          Automated testing tool for Successfactor and Workday
                          tenants with proven test cases, test scripts
                          management and sensitive data masking functionality.
                        </p>
                        <Link to="/aksa-studio" className="btn">
                          More Details{" "}
                          <i className="flaticon-arrow-pointing-to-right" />
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="title-description">
                        <h3>Reorg Chart Modelling</h3>
                        <p>
                          Simulate structural changes in your org chart with our
                          reorg features. Our reorg modelling tool helps you
                          plan organizational transformation and model future
                          hierarchies directly in your org chart.
                        </p>
                        <Link to="/reorg-chart-modelling" className="btn">
                          More Details{" "}
                          <i className="flaticon-arrow-pointing-to-right" />
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          <section className="why-choose-mcbitss m-shape">
            <Row justify="center">
              <Col span={20}>
                <h2>
                  <span>Why Choose Mcbitss's</span> Data Migration & Integration
                  Solutions?
                </h2>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="description card-1">
                        <h3>Reduce Complexity</h3>
                        <ul>
                          <li>
                            Mcbitss pre-configured solution that integrates SAP
                            and cloud HR solutions (Successfactor and Workday,
                            peoplesoft, etc.) makes data integration more simple
                            and effective
                          </li>
                          <li>
                            From the Analysis phase until the post Go Live
                            activities, we are present in all project phases to
                            provide support and help with all tasks related to
                            migration or integration of HR data
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="description card-3">
                        <h3>Reduce Costs</h3>
                        <ul>
                          <li>
                            Decrease the workload by 48% due to our expertise
                            and pre-configured tools
                          </li>
                          <li>
                            Reduce the number of errors in each data delivery,
                            and facilitate the design of the integration, which
                            impacts directly in the cost of the project
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="description card-2">
                        <h3>Reduce Risk and Exposure</h3>
                        <ul>
                          <li>
                            Ensure a successful and smooth Go-Live of the
                            project thanks to total control of the data
                          </li>
                          <li>
                            Diminish all manual actions and repetitive tasks by
                            automating all the processes related to Migration &
                            Integration
                          </li>
                          <li>
                            Avoid the migration of corrupted HR data in your
                            Cloud HR system by Analysis and cleansing phases
                            ensuring your data quality
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          {/* <!--Testimonials starts--> */}
          <section className="testimonials circle-shape">
            <Row justify="center">
              <Col span={20}>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="testimonials-content">
                      <span>Testimonials</span>
                      <h3>Check what our client Says about us</h3>

                      <div className="testimonials-list">
                        <Slider {...testimonalsettings}>
                          <div className="list">
                            <div className="details">
                              <div className="customer-message">
                                <p>
                                  Excellent execution of data migration from
                                  legacy systems including SAP HR Core to
                                  Successfactor and Workday. All integrations
                                  are built with minimum integration faults. We
                                  are able to go-live on-time in waves.{" "}
                                </p>
                              </div>
                              <div className="profile">
                                <img
                                  src={require("../../assets/images/mnc-companies.jpg")}
                                  alt="Large Pharmaceutical Company USA"
                                />
                                <h4>Large Pharmaceutical Company</h4>
                                <h5>USA </h5>
                              </div>
                            </div>
                          </div>

                          <div className="list">
                            <div className="details">
                              <div className="customer-message">
                                <p>
                                  We are extremely happy with Successfactor and
                                  Workday tenant testing for version and patch
                                  upgrades. Excellent automation tool for
                                  Workday tenant testing which helped us to
                                  reduce overall testing efforts and save cost
                                  on testing{" "}
                                </p>
                              </div>
                              <div className="profile">
                                <img
                                  src={require("../../assets/images/mnc-companies.jpg")}
                                  alt="Large Pharmaceutical Company Switzerland"
                                />
                                <h4>Large Pharmaceutical Company</h4>
                                <h5>Switzerland</h5>
                              </div>
                            </div>
                          </div>

                          <div className="list">
                            <div className="details">
                              <div className="customer-message">
                                <p>
                                  We are proud of our partnership with McBitss.
                                  They delivered excellent service towards data
                                  extraction, transformation and cleansing to
                                  create Workday specific Workday templates. We
                                  strongly recommend for data migration projects
                                  from SAP to Workday.
                                </p>
                              </div>
                              <div className="profile">
                                <img
                                  src={require("../../assets/images/mnc-companies.jpg")}
                                  alt="Successfactor and Workday Partner USA"
                                />
                                <h4>Successfactor and Workday Partner</h4>
                                <h5>USA</h5>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}
export default Home;
