import React from 'react';
import './Privacy.css';

/* eslint-disable */

class Privacy extends React.Component {
  render() {
    return (
      <div className="privacy-text-content">
        <p><u>Introduction</u></p>
        <p>Your privacy is important to us. We believe it is important for you to know how we treat the information we receive from you, on the Internet. This privacy statement explains what personal data we, Mcbitss GMBH (“Mcbitss”, “Mcbitss” or “we”), collect from you, through our interactions with you on this website, and how we use that data.</p>
        <p>This Privacy Terms apply to the main website www.mcbitss.com as well as&nbsp; in the&nbsp;<a href="http://sites.mcbitss.com/">sites.Mcbitss.com</a>&nbsp;sub-domain (sometimes called “microsites”). Please note that these websites may include links to websites of third parties whose privacy practices differ from those of Mcbitss. If you provide personal data to any of those websites your data is governed by their privacy statements.</p>
        <p><u>Scope and Usage of Data</u></p>
        <p><b>Personal information you give to us</b></p>
        <p>Mcbitss collects data to operate this website. You provide some of this data directly, such as when you submit Investor Subscription form, Internship Application form, Research Application form, Contact us form, download gated assets form and microsite form. Typically, the contact information we collect is used by us to respond to your enquiry, or allow you to access specific account information. We may execute other activities such as marketing campaign, promotional communications for which consent is taken appropriately or another appropriate lawful basis for the processing is identified.</p>
        <p>In general, you can visit Mcbitss on the World Wide Web without telling us who you are. Our web servers or affiliates who provide analytics and performance enhancement services collect IP address, operating system details, browsing details, device details and language settings. This information is aggregated to measure the number of visits, average time spent on the site, pages viewed and similar information. Mcbitss uses this information to measure the site usage, improve content and to ensure safety and security as well enhance performance of the website.</p>
        <p>You can make choices about our collection and use of your data. For example, you may want to access, edit or remove the personal information in your Mcbitss website or microsite account, change your password, or close your account. When you are asked to provide personal data, you may decline. The data we collect depends on the context of your interactions with Mcbitss, and the choices you make, including your privacy settings. The data we collect can include the following:</p>
        <ul>
          <li>Name and contact data: We collect first and last name, email address, postal address, country, employer, phone number and other similar contact data</li>
          <li>Credentials: We collect passwords used for authentication and account access, where this function is available.</li>
        </ul>
        <p><u>Purpose of Data Uses</u></p>
        <p>Mcbitss uses the data we collect for 2 main purposes, described in more detail below: to operate our business and this website; and to send communications, including promotional communications.</p>
        <ul>
          <li>Security and Safety: We use data to protect the security and safety of our website.</li>
          <li>Communications: We use data we collect to communicate with you and personalize our communications with you.&nbsp; Additionally, you can sign up for email subscriptions and choose whether you wish to receive promotional communications from Mcbitss by email, SMS, post and telephone or other means of communication. For information about managing your contact data, email subscriptions and promotional communications, please submit a request to us using our contact form at <a href="https://www.mcbitss.com/contact">https://www.mcbitss.com/contact</a>.</li>
        </ul>
        <p><u>Sharing of data</u></p>
        <p>We share personal data among Mcbitss-controlled affiliates and subsidiaries. We also share personal data with vendors or agents working on our behalf for the purposes described in this statement. For example, companies we've hired to assist in protecting and securing our systems and services may need access to personal data to provide those functions.</p>
        <p>We usually do not share other personal data collected from the websites with other third parties. However, this may happen if:</p>
        <ol>
          <li>You request or authorize us to do so;</li>
          <li>We need to comply with applicable law or respond to valid legal process</li>
          <li>We need to operate and also maintain the security of this website, including to prevent or stop an attack on our computer systems or networks;</li>
        </ol>
        <p><u>Cookies</u></p>
        <p>Mcbitss uses cookies (small text files placed on your device) and similar technologies to provide our websites and to help collect data. The text in a cookie often consists of a string of numbers and letters that uniquely identifies your computer, but it can contain other information as well.</p>
        <p>Our Use of Cookies and Similar Technologies - Mcbitss uses cookies and similar technologies for several purposes, which may include:</p>
        <ul>
          <li>Storing your Preferences and Settings - Settings that enable our website to operate correctly or that maintain your preferences over time may be stored on your device.</li>
          <li>Sign-in and Authentication - When you sign into our website using your credentials, we store a unique ID number, and the time you signed in, in an encrypted cookie on your device. This cookie allows you to move from page to page within the site without having to sign in again on each page. You can also save your sign-in information so you do not have to sign in each time you return to the site.</li>
          <li>Security - We use cookies to detect fraud and abuse of our websites and services.</li>
          <li>Social Media - Some of our websites include social media cookies, including those that enable users who are logged in to the social media service to share content via that service.</li>
          <li>Analytics - In order to provide our website efficiently, we use cookies and other identifiers to gather usage and performance data. For example, we use cookies to count the number of unique visitors to a web page or service and to develop other statistics about the operations of our website. This includes cookies from Mcbitss and from third-party analytics providers.</li>
          <li>Performance - Mcbitss uses cookies for load balancing to ensure that websites remain up and running.</li>
        </ul>
        <p>In addition to the cookies Mcbitss sets when you visit our websites, third parties may also set cookies when you visit Mcbitss sites. In some cases, that is because we have hired the third party to provide services on our behalf, such as site analytics. In particular, we use cookies from Google Analytics (<a href="https://support.google.com/analytics/answer/6004245" target="_blank">read Google’s privacy policy</a>) and Adobe Analytics (<a href="https://www.adobe.com/uk/privacy.html" target="_blank">read Adobe’s privacy policy</a>) for website analytics and metrics, Akamai (<a href="https://www.akamai.com/uk/en/about/compliance/data-protection-at-akamai.jsp" target="_blank">read Akamai’s privacy policy</a>) for website security, Disqus (<a href="https://help.disqus.com/terms-and-policies/disqus-privacy-policy" target="_blank">read Disqus’ privacy policy</a>) to enable comments to our blog posts, Marketo (<a href="https://documents.marketo.com/legal/privacy/" target="_blank">read Marketo’s privacy policy</a>) for marketing purposes, AddThis (<a href="http://www.addthis.com/privacy/privacy-policy" target="_blank">read AddThis’ privacy policy</a>) and LinkedIn (<a href="https://www.linkedin.com/legal/cookie-policy" target="_blank">read LinkedIn’s privacy policy</a>) for social interactions; if you want to know more about these services use cookies and personal data, please refer to the privacy notices directly on each third party’s website.</p>
        <p>Some of the cookies we commonly use are listed below. This list is not exhaustive, but it is intended to illustrate the main reasons we typically set cookies. If you visit one of our websites, the site may set some or all of the following cookies:</p>

        <p><u>How to Control Cookies</u></p>
        <p>Most web browsers automatically accept cookies but provide controls that allow you to block or delete them. For example, certain browser allow to block or delete cookies by clicking Settings &gt; Privacy &gt; Cookies. To find information about specific browsers, please visit the browser developer’s website.</p>
        <p><u>Your Communications Preferences</u></p>
        <p>If you receive promotional email from us and would like to opt out, you can do so by following the directions in those emails. You can also opt out of email subscriptions and choose whether to share your contact information with Mcbitss.</p>
        <p><u>How to Contact Us</u></p>
        <p>If you have a privacy concern, complaint or a question regarding this privacy statement, please direct it to the Chief Privacy Officer of Mcbitss at&nbsp;<a href="mailto:info@mcbitss.com">info@mcbitss.com</a>&nbsp;or contact us through this webform: <a href="https://www.mcbitss.com/contact">https://www.mcbitss.com/contact</a>.</p>
        <p><u>European Data Protection Rights - GDPR</u></p>
        <p>When we process personal data about you, we do so with your consent and/or as necessary to provide the website you use, operate our business, meet our contractual and legal obligations, protect the security of our systems and our customers, or fulfill other legitimate interests of Mcbitss as described in this privacy statement.</p>
        <p>Below, you will find additional privacy information that you may find important. Mcbitss adheres to applicable data protection laws in the European Economic Area, which if applicable includes the following rights:</p>
        <ul>
          <li>If the processing of personal data is based on your consent, you have a right to withdraw consent at any time for future processing;</li>
          <li>You have a right to request from us, a “data controller” as defined in the law, access to and rectification of your personal data;</li>
          <li>You have a right to request from us that your personal data are erased, subject to certain exceptions;</li>
          <li>You have a right to object to the processing of your personal data; and</li>
          <li>You have a right to lodge a complaint with a data protection authority.</li>
        </ul>
        <p><u>Security of Personal Data</u></p>
        <p>Mcbitss is committed to protecting the security of your personal data. We use a variety of security technologies and procedures to help protect your personal data from unauthorized access, use or disclosure.</p>
        <p><u>Where We Store and Process Personal Data</u></p>
        <p>Personal data collected by Mcbitss may be stored and processed in your region, in India, and in any other country where Mcbitss or its affiliates, subsidiaries or service providers operate facilities. The storage location(s) are chosen in order to operate efficiently, to improve performance and to create redundancies in order to protect the data in the event of an outage or other problem. We take steps to ensure that the data we collect under this privacy statement is processed according to the provisions of this statement and the requirements of applicable law wherever the data is located.</p>
        <p>We transfer personal data from the European Economic Area and Switzerland to other countries, some of which have not been determined by the European Commission to have an adequate level of data protection. When we do, we use a variety of legal mechanisms, including contracts, to help ensure your rights and protections travel with your data.</p>
        <p><u>Our Retention of Personal Data</u></p>
        <p>Mcbitss retains personal data for as long as necessary to provide the access to and use of the website, or for other essential purposes such as complying with our legal obligations, resolving disputes and enforcing our agreements. Because these needs can vary for different data types and purposes, actual retention periods can vary significantly.<u></u></p>
        <p><u>Changes to This Privacy Statement</u></p>
        <p>We may update this privacy statement from time to time. We encourage you to periodically review this privacy statement to learn how Mcbitss is protecting your information.</p>
        <p><u>Data Protection Officer</u></p>
        <p>Mcbitss has appointed internal Data Protection Officers who can be contacted for any questions or concerns about Mcbitss’ privacy policies or practices. Depending on your location, you can refer to:</p>

      </div>
    );
  }
}
export default Privacy;
