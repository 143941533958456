import React from "react";
import Slider from "react-slick";
import { connect } from 'react-redux';
import { createrequestdemo } from '../../actions/requestdemoactions';
import Requestdemoform from '../Requestdemoform';
import { Row, Col } from 'antd';
import Layout from "../Layout";
import "./Dc3.scss";

const featuresslidesettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  adaptiveHeight: true,
  fade: true,
  cssEase: 'linear'
};

class Dc3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openform: false,
      name: '',
      email: '',
      mobile: ''
    };
  }

  openpopup = () => {
    this.setState({ openform: true });
  };

  closeform = () => {
    this.setState({ openform: false });
  };

  getdata = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  submitform = () => {
    const { name, email, mobile } = this.state;
    let obj = {}
    obj.name = name;
    obj.email = email;
    obj.mobile = mobile;
    obj.product = 'Dc3'
    this.props.dispatch(Requestdemoform(obj))
    this.setState({ openform: false });
  }
  render() {
    const { openform } = this.state;
    return (
      <React.Fragment>

        <Layout>
          {/* <!--McBitss Products starts--> */}
          <div className="mcbitss-products dc-product">
            <div className="product-details">
              <Row justify="center">
                <Col span={20}>
                  <Row justify="center" gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <h1>Dc3</h1>
                      <p>Collects data from any data source, cleanse with approval workflow and consolidates the data to workday templates. Built-in preconfigured conversion rules to migrate data from <strong>SAP HCM to workday</strong>. Also includes data quality checks and error reporting based on workday configurations. OCR to read PDF Files to update meta data (used for reading contracts and read information automatically to store in DB)</p>
                      {/* <a href={require("../../assets/documents/Dc3.pdf")} target="_blank" className="btn btn-primary">Download Brochure </a> */}
                      <a onClick={this.openpopup} className="btn">
                        Request Demo
                    </a>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <img src={require("../../assets/images/products-inner/dc3.svg")} alt="dc3" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          {/* <!--McBitss Products starts--> */}

          <div className="dynex-features">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="heading">
                      <h2>What advantages does the Dc3 offer you?</h2>
                    </div>
                  </Col>
                </Row>

                <Row justify="center">
                  <Col span={24}>
                    <div className="details">
                      <Row justify="center" gutter={{ xs: 10, sm: 16, md: 16, lg: 16 }}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Data collection </p>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Data Cleaning </p>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Data Consolidation </p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Data Anonymization </p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Audit Trail </p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Approval workflow </p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Authorization </p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Workflow based tool </p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>File migration utility </p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Archive utility </p>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                          <p>Task Scheduler </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

              </Col>
            </Row>
          </div>

          <div className="mcbitss-module-points">
            <Row justify="center">
              <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 16 }} lg={{ span: 16 }}>
                <div className="heading">
                  <h2>Dc3 Modules</h2>
                </div>

                <div className="module-list">
                  <Row justify="center" gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <img src={require("../../assets/images/dc3/data-collection.jpg")} alt="Data Collection" />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <h3>Data Collection</h3>
                      <p>Easy drag and drop data collection from various data sources like flat files, excel, databases, ERP, BI, API and Web services most flexibly</p>
                    </Col>
                  </Row>
                </div>
                {/* row ends */}

                <div className="module-list">
                  <Row justify="center" gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12, push: 12 }} lg={{ span: 12, push: 12 }}>
                      <img src={require("../../assets/images/dc3/data-cleancing.jpg")} alt="Data Cleansing" />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12, pull: 12 }} lg={{ span: 12, pull: 12 }}>
                      <h3>Data Cleansing</h3>
                      <p>Provides powerful non-script and script based automated cleansing and user friendly manual data cleansing through approval flow. </p>
                    </Col>
                  </Row>
                </div>
                {/* list ends here */}

                <div className="module-list">
                  <Row justify="center" gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <img src={require("../../assets/images/dc3/data-consolidation.jpg")} alt="Data Consolidation" />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <h3>Data Consolidation</h3>
                      <p>Collected and cleansed data can be aggregated, grouped, harmonized and consolidated for further down stream system processing or export. </p>
                    </Col>
                  </Row>
                </div>
                {/* row ends */}

                <div className="module-list">
                  <Row justify="center" gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12, push: 12 }} lg={{ span: 12, push: 12 }}>
                      <img src={require("../../assets/images/dc3/security.jpg")} alt="Security" />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12, pull: 12 }} lg={{ span: 12, pull: 12 }}>
                      <h3>Security</h3>
                      <p>Security ensures compliance and protect data, applications, data manipulation and data approvals. Flexible role based access provides strong control over data management. </p>
                    </Col>
                  </Row>
                </div>
                {/* list ends here */}

                <div className="module-list">
                  <Row justify="center" gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <img src={require("../../assets/images/dc3/export-data.jpg")} alt="Export data from DC3" />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <h3>Export</h3>
                      <p>Data can be exported to target systems via direct system transfer,  flat files, excel files, API, Webservice call and template based excel files. </p>
                    </Col>
                  </Row>
                </div>
                {/* row ends */}

                <div className="module-list">
                  <Row justify="center" gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12, push: 12 }} lg={{ span: 12, push: 12 }}>
                      <img src={require("../../assets/images/dc3/audit-log.jpg")} alt="Audit logs" />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12, pull: 12 }} lg={{ span: 12, pull: 12 }}>
                      <h3>Audit logs</h3>
                      <p>Provides flexible configuration of logs on various data points and changes for manual data cleansing and maintenance for transparency and control.</p>
                    </Col>
                  </Row>
                </div>
                {/* list ends here */}

              </Col>
            </Row>
          </div>


          {/* <!--Fun Facts section starts--> */}
          <div className="fun-facts gray-bg circle-shape-gray">
            <Row justify="center">
              <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="heading">
                  <h2>Products Facts and Figures</h2>
                </div>
                <Row justify="center" gutter={20}>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                    <div className="list">
                      <h3>10+</h3>
                      <p>Customers</p>
                    </div>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                    <div className="list">
                      <h3>2m+</h3>
                      <p>Transactions</p>
                    </div>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                    <div className="list">
                      <h3>1m+</h3>
                      <p>Files</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* //  <!--Fun Facts section ends--> */}


          {/* <!--features-video ends--> */}
          {/* <div className="features-video ">
          <div className="container">
            <div className="row">
              <h3>Feature Demo Videos</h3>
              <div className="col-sm-8 col-sm-push-2">
                <iframe
                  src="https://www.youtube.com/embed/ltHGK6eymeo?rel=0"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                />
              </div>
            </div>
          </div>
        </div> */}
          {/* <!--features-video ends--> */}

          {/* <!--Request Call Back starts */}
          {/* <div className="request-call-back" id="request-call-back">
	<div className="container">
		<div className="row">
			<div className="col-sm-6 col-md-6 col-lg-6">
				<img src="images/request-call-back.svg" alt="McBitss Request a call back" title="Request a call back" />
				<h3>Request a call back</h3>
				<p>For Business inquiry fill our short feedback form or send us an email and we’ll get in touch shortly.</p>
				
			</div>
			<div className="col-sm-6 col-md-6 col-lg-6">
				<form action="">
					<div className="form-fields">
						<label>Name</label>
						<input type="text" name="" id="" required placeholder="Your Name"/>
					</div>
					<div className="form-fields">
						<label>Email</label>
						<input type="email" name="" id="" required placeholder="Your Email ID"/>
					</div>
					<div className="form-fields">
						<label>Phone</label>
						<input type="tel" name="" id="" required placeholder="Your Phone Number"/>
					</div>
					<div className="form-fields">
						<input type="button" value="Send"/>
					</div>
				</form>
				
			</div>
		</div>
	</div>
 </div> */}
          {/* Request Call Back ends--> */}

          {/* <!--Call back popup starts here--> */}
          {
            openform ? (
              <Requestdemoform
                closeform={this.closeform}
                submitform={this.submitform}
                getdata={this.getdata}
              />
            ) : (
                ""
              )
          }
          {/* <!--Value-adds ends--> */}
        </Layout >
      </React.Fragment >
    );
  }
}

const mapStateToProps = state => ({
  users: state.SpmReducer.users
});

export default connect(mapStateToProps)(Dc3);
