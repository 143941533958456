import React, { Component } from "react";
import { Link, withRouter } from "react-router";
import "./Header.scss";
import mainlogo from "../../assets/images/logo1.png";
import { Row, Col } from "antd";

class Header extends React.Component {
  componentDidMount() {
    window.onscroll = function () {
      myFunction();
    };

    var header = document.getElementById("main-navigation");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    //Onscroll sticky Header function ends here
  }
  searchtext = () => {
    var element = document.getElementById("search");
    element.classList.add("open");
    // var search = document.getElementsByClassName("searchfocus");
    // search.focus()
  };
  togglemenu = () => {
    // const element = document.getElementById('center-menu')
    // // document.getElementById('center-menu').style.display='block';
    // console.log( 'element')
    // if(element.style.display === 'block'){
    // 	element.style.display ='none'
    // } else{
    // 	element.style.display === 'block'
    // }

    const x = document.getElementById("center-menu");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  };

  closesearchbar = () => {
    var element = document.getElementById("search");
    element.classList.remove("open");
  };
  render() {
    const { location } = this.props;
    const routeArr = [
      "/",
      "/about",
      "/products",
      "/services",
      "/solutions",
      "/contact",
      "/disclaimer",
      "/privacy-policy",
      "/case-study",
      "/blog",
      "/faq",
      "/blog-details",
    ];
    return (
      //     <nav className="main-navigation" id="main-navigation">
      //       <div  className={location.pathname==="/" ? "main-navigation" : "main-navigation inner-nav"}>
      //       <div className="container">
      //        <nav>
      //            {/* <!--Logo --> */}
      //            <div className="brand">
      //                <Link to="/"><img src="images/logo.png" alt="McBitss" /></Link>
      //            </div>

      //            {/* <!--Navigation starts--> */}
      //            <div className="center-menu">
      //                <ul>
      //                  <li className="active"><Link href="/">Home</Link></li>
      //                  <li><Link to ="about">About</Link></li>
      //                  <li><a href="#">Products <i className="fa fa-angle-down" aria-hidden="true"></i></a>
      //                    {/* <ul className="dropdown">
      //                        <li><a href="#">App Designer</a></li>
      //                        <li><Link to="/tas">TAS</Link></li>
      //                        <li><Link to="/spm">SPM</Link></li>
      //                        <li><a href="#">Information Security</a></li>
      //                        <li><a href="#">Student Verification Portal</a></li>
      //                        <li><a href="#">Pre Clinical trials and Animal Warehouse</a></li>
      //                        <li><a href="#">Reorg Charts</a></li>
      //                        <li><a href="#">DLT Wired</a></li>
      //                        <li><a href="#">DC3</a></li>
      //                        <li><a href="#">DynEx</a></li>
      //                        <li><a href="#">E-Invoicing</a></li>
      //                     </ul> */}
      //                  </li>
      //                  <li><a href="#">Services <i className="fa fa-angle-down" aria-hidden="true"></i></a>
      //                    {/* <ul className="dropdown">
      //                        <li><a href="#">Cloud,Workload,Platforms</a></li>
      //                        <li><a href="#">Workplace & Mobilit</a></li>
      //                        <li><a href="#">Analytics</a></li>
      //                        <li><a href="#">Security</a></li>
      //                        <li><a href="#">Application Services</a></li>
      //                        <li><a href="#">Buisiness process Services</a></li>
      //                        <li><a href="#">Enterprise & Cloud Apps</a></li>
      //                        <li><a href="#">Consulting</a></li>
      //                        <li><a href="#">Industry Solutions</a></li>
      //                        <li><a href="#">SAP BW4/HANAs</a></li>
      //                      </ul> */}
      //                  </li>
      //                  <li><a href="#">Solutions <i className="fa fa-angle-down" aria-hidden="true"></i></a>
      //                    {/* <ul className="dropdown">
      //                        <li><a href="#">Blockchain</a></li>
      //                        <li><a href="#">Machine Learning</a></li>
      //                        <li><a href="#">Predictive Analysis</a></li>
      //                        <li><a href="#">Internet of things</a></li>
      //                        <li><a href="#">Anti-Counterfit</a></li>
      //                        <li><a href="#">Enterprise and Modern Web/Mobile applications</a></li>
      //                        <li><a href="#">Information Security</a></li>
      //                        <li><a href="#">Data Management & Archival</a></li>
      //                        <li><a href="#">Files Management and Archival</a></li>
      //                        <li><a href="#">Advanced Visualization and Reporting</a></li>
      //                        <li><a href="#">Automation Tools</a></li>
      //                      </ul> */}
      //                  </li>
      //                  <li><a href="#">Contact</a></li>
      //                  <li><a href="#">Blog</a></li>
      //                </ul>
      //            </div>
      //            {/* <!--Navigation ends--> */}

      //            {/* <!--Right side Language Picker, Search  starts--> */}
      //            <div className="right-menu">
      //                <ul>
      //                    <li><a href=""><img src="images/language/en.png" /> EN</a></li>
      //                    <li><a href=""><i className="fa fa-search" aria-hidden="true"></i></a></li>
      //                 </ul>
      //            </div>
      //            {/* <!--Right side Language Picker, Search  ends--> */}

      //        </nav>
      //      </div>
      // </div>
      // </nav>
      <div
        className={
          routeArr.indexOf(location.pathname) >= 0
            ? "main-navigation"
            : "main-navigation inner-nav"
        }
        id="main-navigation"
      >
        <div className="navigation-style">
          <Row justify="center">
            <Col
              xs={{ span: 23 }}
              sm={{ span: 23 }}
              md={{ span: 21 }}
              lg={{ span: 21 }}
            >
              <nav>
                {/* <!--Logo --> */}
                <div className="brand">
                  <Link to="/">
                    <img src={mainlogo} alt="McBitss" className="white-logo" />
                    <img src={mainlogo} alt="McBitss" className="dark-logo" />
                  </Link>
                </div>
                <div className="mobile-menu">
                  <i className="fa fa-bars" onClick={this.togglemenu}></i>
                </div>
                {/* <!--Navigation starts--> */}

                <div id="center-menu">
                  <div className="center-menu">
                    <ul>
                      <li className={location.pathname === "/" ? "active" : ""}>
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/stakeholders" ? "active" : ""
                        }
                      >
                        <Link to="stakeholders">Stakeholders</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/tools" ? "active" : ""
                        }
                      >
                        <Link to="tools">Tools</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/services" ? "active" : ""
                        }
                      >
                        <Link to="services">services</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/about" ? "active" : ""
                        }
                      >
                        <Link to="about">About Us</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/career" ? "active" : ""
                        }
                      >
                        <Link to="career">Career</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/contact" ? "active" : ""
                        }
                      >
                        <Link to="contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="right-action-button">
                    <Link to="contact">Get Started</Link>
                  </div>
                </div>
                {/* <!--Navigation ends--> */}
              </nav>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default withRouter(Header);
