import React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { createrequestdemo } from "../../actions/requestdemoactions";
import Requestdemoform from "../Requestdemoform";
import { Image, Row, Col } from "antd";
import Layout from "../Layout";
import "./ReorgchartModelling.scss";
import lineleft from "../../assets/images/line_left.png";
import lineright from "../../assets/images/line_right.png";

class ReorgchartModelling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openform: false,
      name: "",
      email: "",
      mobile: "",
    };
  }

  openpopup = () => {
    this.setState({ openform: true });
  };

  closeform = () => {
    this.setState({ openform: false });
  };

  getdata = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  submitform = () => {
    const { name, email, mobile } = this.state;
    let obj = {};
    obj.name = name;
    obj.email = email;
    obj.mobile = mobile;
    obj.product = "Reorg Chart Modelling";
    this.props.dispatch(createrequestdemo(obj));
    this.setState({ openform: false });
  };
  render() {
    const { openform, random, setRandom } = this.state;

    return (
      <React.Fragment>
        <Layout>
          {/* <!--McBitss Products starts--> */}
          <div className="mcbitss-products reorg-product">
            <div className="product-details">
              <Row justify="center">
                <Col span={20}>
                  <Row justify="center" gutter={16}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <h1>Reorg Chart Modelling</h1>
                      <p>
                        <span>
                          Is your company planning to reorganize its hierarchy
                          structure? Model the workforce of tomorrow via drag &
                          drop!
                        </span>
                      </p>
                      <p>
                        Today, organizations have to face changes more
                        frequently and often without much time to prepare. Be it
                        the reorganization of existing employees, positions or
                        org units, or a business extension based on a merger or
                        acquisition – our{" "}
                        <strong>
                          reorg modelling tool helps you plan organizational
                          transformation
                        </strong>{" "}
                        and model future hierarchies directly in your org chart.
                      </p>
                      {/* <a href={require("../../assets/documents/reorg-chart-modelling.pdf")} target="_blank" className="btn btn-primary">Download Brochure </a> */}
                      <a onClick={this.openpopup} className="btn">
                        Request Demo
                      </a>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <img
                        src={require("../../assets/images/products-inner/reorg-modeling.jpg")}
                        alt="Reorg Chart Modelling"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          {/* <!--McBitss Products starts--> */}

          {/* features-sliders starts*/}
          <div className="features-sliders gray-bg">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <div className="heading">
                  <h2>
                    Simulate structural changes in your org chart with the our
                    reorg features
                  </h2>
                </div>

                {/* Slider section starts */}
                <div className="features-slide">
                  <div className="list">
                    <div className="left-cont">
                      <h3>Views</h3>
                      <ul>
                        <li>
                          Separate views are maintained for the each roles
                          namely OrgAdmin, OrgPlanner, User and EndUser{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/role.jpg")}
                        alt="role"
                      />
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <img src={lineright} alt="Aksa Studio" />
                  </div>

                  <div className="list">
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/cards.jpg")}
                        alt="cards"
                      />
                    </div>

                    <div className="left-cont">
                      <h3>Cards</h3>
                      <ul>
                        <li>Different predefined styles</li>
                        <li>Custom Styles – create your own card</li>
                        <li>Budget view</li>
                      </ul>
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <img src={lineleft} alt="Reorg Modelling" />
                  </div>

                  <div className="list">
                    <div className="left-cont">
                      <h3>Download</h3>
                      <ul>
                        <li>Download into PDF formats</li>
                        <li>Download Version(excel sheet)</li>
                        <li>
                          Download All Level PDF by configuring required fields
                          in a table{" "}
                        </li>
                        <li>Download All Level PPT using custom template</li>
                      </ul>
                    </div>
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/download.jpg")}
                        alt="playground"
                      />
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <img src={lineright} alt="Reorg Modelling" />
                  </div>

                  <div className="list">
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/playground.jpg")}
                        alt="playground"
                      />
                    </div>
                    <div className="left-cont">
                      <h3>Playground</h3>
                      <ul>
                        <li>View to see different versions</li>
                        <li>Split view to change the org structure</li>
                        <li>Drag and Drop nodes to reorganize org structure</li>
                        <li>Add new position using existing data</li>
                        <li>Robust search functionality </li>
                        <li>Generate PPT custom template</li>
                      </ul>
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <img src={lineleft} alt="Reorg Modelling" />
                  </div>

                  <div className="list">
                    <div className="left-cont">
                      <h3>Roles</h3>
                      <ul>
                        <li>
                          Admin – To create initial version and finalize the
                          version
                        </li>
                        <li>
                          Planner – To create new version using OrgAdmin version
                        </li>
                        <li>User – To view OrgAdmin/OrgPlanner versions </li>
                        <li>
                          EndUser – To view finalized version(OrgAdmin Version)
                        </li>
                      </ul>
                    </div>
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/role.jpg")}
                        alt="Roles"
                      />
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <img src={lineright} alt="Reorg Modelling" />
                  </div>

                  <div className="list">
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/upload.jpg")}
                        alt="Upload"
                      />
                    </div>
                    <div className="left-cont">
                      <h3>Upload</h3>
                      <ul>
                        <li>
                          Upload excel sheets to create either
                          OrgAdmin/OrgPlanner versions
                        </li>
                        <li>
                          Upload custom PPT Template to generate custom org
                          structure
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <Image src={lineleft} alt="Reorg Modelling" />
                  </div>

                  <div className="list">
                    <div className="left-cont">
                      <h3>Add Picture</h3>
                      <ul>
                        <li>
                          Add Picture – User can Add/Update the new photos of an
                          associate{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/add-picture.jpg")}
                        alt="Add Picture"
                      />
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <img src={lineright} alt="Reorg Modelling" />
                  </div>

                  <div className="list">
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/settings.jpg")}
                        alt="Settings"
                      />
                    </div>
                    <div className="left-cont">
                      <h3>Settings</h3>
                      <ul>
                        <li>
                          Select Shape -- User can select the shape of the node
                        </li>
                        <li>
                          Split Screen Direction -- User can view Horizontal or
                          Vertical split screen to change the org structure
                        </li>
                        <li>
                          Select View – User can select the existing node
                          template{" "}
                        </li>
                        <li>
                          Node Line Color  -- User can change the node line
                          color
                        </li>
                        <li>
                          Node Box Border Color -- User can change the node box
                          border color
                        </li>
                        <li>
                          Org Chart Type – User can view Orgchart either in
                          Landscape or in Portrait mode
                        </li>
                        <li>Skin selection – User can select the skin type</li>
                        <li>
                          Template creation -- User can create their own custom
                          template{" "}
                        </li>
                        <li>Levels – User can choose levels</li>
                        <li>
                          Show Picture –User can show/hide the picture in the
                          hierarchy
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <Image src={lineleft} alt="Reorg Modelling" />
                  </div>

                  <div className="list">
                    <div className="left-cont">
                      <h3>Check/UnCheck Assistance</h3>
                      <ul>
                        <li>
                          Check/UnCheck Assistance – to show/hide additional
                          assistant node
                        </li>
                      </ul>
                    </div>
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/reorg-chart-modelling/check-uncheck-asistance.jpg")}
                        alt="Check/UnCheck Assistance"
                      />
                    </div>
                  </div>
                  {/* List ends here */}
                </div>
                {/* slider section ends here */}
              </Col>
            </Row>
          </div>
          {/* features-sliders ends */}

          <div className="reorg-features">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
              >
                <div className="heading">
                  <h2>Reorg Chart Modelling Module</h2>
                </div>

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <img
                        src={require("../../assets/images/reorg/Org-User.jpg")}
                        alt="Org User"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <h3>Org User</h3>
                      <ul>
                        <li>
                          He/She can only see all the version of Org Planner.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* row ends */}

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, push: 12 }}
                      lg={{ span: 12, push: 12 }}
                    >
                      <img
                        src={require("../../assets/images/reorg/org-planner.jpg")}
                        alt="Org Planner"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, pull: 12 }}
                      lg={{ span: 12, pull: 12 }}
                    >
                      <h3>Org Planner</h3>
                      <ul>
                        <li>
                          He/She can restructure the part of the Organization
                          chart which is uploaded by Org Admin.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* list ends here */}

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <img
                        src={require("../../assets/images/reorg/org-admin.jpg")}
                        alt="Org Admin"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <h3>Org Admin</h3>
                      <ul>
                        <li>
                          Will upload the Initial version of the Organization
                          Chart
                        </li>
                        <li>
                          And then He/she has an ability to finalize the version
                          created by the Org Planner.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* row ends */}

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, push: 12 }}
                      lg={{ span: 12, push: 12 }}
                    >
                      <img
                        src={require("../../assets/images/reorg/end-user.jpg")}
                        alt="Data Cleansing"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, pull: 12 }}
                      lg={{ span: 12, pull: 12 }}
                    >
                      <h3>End User</h3>
                      <ul>
                        <li>
                          It is open to all in that Organization and can view
                          only the Finalized version of Org Admin.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* list ends here */}
              </Col>
            </Row>
          </div>

          {/* <!--Fun Facts section starts--> */}
          {/* <div className="fun-facts gray-bg circle-shape-gray">
            <Row justify="center">
              <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 18 }} lg={{ span: 18 }}>
                <div className="heading">
                  <h2>Products Facts and Figures</h2>
                </div>
                <Row justify="center" gutter={20}>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                    <div className="list">
                      <h3>3+</h3>
                      <p>Customers</p>
                    </div>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                    <div className="list">
                      <h3>10000+</h3>
                      <p>No. of Employee’s</p>
                    </div>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                    <div className="list">
                      <h3>1</h3>
                      <p>Supported Languages </p>
                    </div>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                    <div className="list">
                      <h3>100</h3>
                      <p>TBD </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div> */}
          {/* //  <!--Fun Facts section ends--> */}

          {/* for more information starts*/}

          <div className="for-more-information gray-bg">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <h4>
                  For more information about <span>Reorg Chart Modelling</span>{" "}
                  <br />
                  check our Website
                </h4>
                <a href="https://appdemosstudio.com/orgchart" target="_blank">
                  Go to website
                </a>
              </Col>
            </Row>
          </div>

          {/* for more information ends */}

          {/* <!--Call back popup starts here--> */}
          {openform ? (
            <Requestdemoform
              closeform={this.closeform}
              submitform={this.submitform}
              getdata={this.getdata}
            />
          ) : (
            ""
          )}
          {/* <!--Value-adds ends--> */}
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.SpmReducer.users,
});

export default connect(mapStateToProps)(ReorgchartModelling);
