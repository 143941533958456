import React, { Component } from "react";
import { Link } from "react-router";
import Slider from "react-slick";
import Footer from "../Footers/Footers";
import Header from "../Headers/Header";
import whowehelp from "../../assets/images/who-we-help.png";
import "./StakeHolders.scss";
import { Row, Col } from "antd";
import { Tabs } from "antd";
import Layout from "../Layout";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";

const { TabPane } = Tabs;

const StakeHolderssettings = {
  arrows: false,
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  margin: 20,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

class StakeHolders extends React.Component {
  // steps starts
  state = {
    current: 0,
  };

  onChange = (current) => {
    console.log("onChange:", current);
    this.setState({ current });
  };

  //   steps ends

  render() {
    // steps starts
    const { current } = this.state;

    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum stakeholders",
                title: "Stakeholders",
                description:
                  "We help your workforce achieve their personal life and work ambitions",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          <section className="who-do-we-help">
            <div className="container">
              <Row justify="center">
                <Col span={20}>
                  <Row justify="center" gutter={16}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 18 }}
                      lg={{ span: 18 }}
                    >
                      <img src={whowehelp} alt="Stakeholders Who we help?" />
                      <h2>
                        We help your workforce achieve their personal life and
                        work ambitions
                      </h2>
                      <p>
                        The economy and the work-life have changed
                        significantly. We have seen shifts in the way we live,
                        how we work, how we do business and the technologies we
                        use to stay in touch. Such massive changes are
                        increasing the importance of the role of HR within
                        organizations.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </section>

          <section className="roles-transformation circle-shape">
            <Row justify="center">
              <Col span={20}>
                <h2>Who do we help?</h2>
                <p>
                  Below we list 8 Key Transformation Roles within organizations.
                </p>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <Slider {...StakeHolderssettings}>
                      <div className="list">
                        <div className="details">
                          <h4>HR Manager</h4>
                          <p>
                            The world is changing, and HR has to deal with all
                            these transformations. Organizations need to be
                            competitive and work with the best talent.
                          </p>
                        </div>
                      </div>
                      {/* ends here */}

                      <div className="list">
                        <div className="details">
                          <h4>Chief Technology Officer</h4>
                          <p>
                            The world has evolved rapidly with technological
                            innovation and differentiation. Supporting key
                            business decisions and better understanding the
                            latest trends and technologies to deliver the best
                            to our customers is crucial.
                          </p>
                        </div>
                      </div>
                      {/* ends here */}

                      <div className="list">
                        <div className="details">
                          <h4>HR Project Manager</h4>
                          <p>
                            Managing projects is hard work. Effective planning,
                            controlling and monitoring makes a difference to
                            deliver the project within budget, on schedule and
                            with high quality.
                          </p>
                        </div>
                      </div>
                      {/* ends here */}

                      <div className="list">
                        <div className="details">
                          <h4>HRIS Director</h4>
                          <p>
                            Defining the HR information system requirements to
                            meet data management needs, such as the accuracy of
                            employee data and integrity are imperative. This
                            requires excellent coordination with IT, finance and
                            the HR department.
                          </p>
                        </div>
                      </div>
                      {/* ends here */}

                      <div className="list">
                        <div className="details">
                          <h4>Chief Executive Officer</h4>
                          <p>
                            Navigating in a rapidly evolving world is a
                            challenge in which trust and clear communication
                            with your employees are indispensable. Focusing on
                            innovation, exceptional skills and customer loyalty
                            to stay ahead of the competition is key.
                          </p>
                        </div>
                      </div>
                      {/* ends here */}

                      <div className="list">
                        <div className="details">
                          <h4>Chief Employee Experience</h4>
                          <p>
                            Creating a people-oriented organization is a must,
                            as great companies are made by great employees.
                            Employee experience is the key driver. Happy
                            employees are more productive, improve recruitment
                            and create a sustainable competitive advantage.
                          </p>
                        </div>
                      </div>
                      {/* ends here */}

                      <div className="list">
                        <div className="details">
                          <h4>Chief Information Officer</h4>
                          <p>
                            In today’s rapidly changing digital environment, it
                            is essential to deliver innovation and added value
                            to organizations. By implementing the right HR
                            solutions and processes, organizations will achieve
                            their goals.
                          </p>
                        </div>
                      </div>
                      {/* ends here */}

                      <div className="list">
                        <div className="details">
                          <h4>Chief Financial Officer</h4>
                          <p>
                            Propel a culture of security. It is not just about
                            revenue, costs and budgets anymore. There is a
                            strong emphasis on managing risk, driving
                            performance and ensuring the integrity and accuracy
                            of company information.
                          </p>
                        </div>
                      </div>
                      {/* ends here */}
                    </Slider>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          {/* <!--contact-us-page starts--> */}
          <section className="hr-transformation">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 18 }}
                    lg={{ span: 18 }}
                  >
                    <div className="top-section">
                      <h2>HR Transformation</h2>
                      <p>
                        HR transformation is the process of fundamentally
                        rethinking and repositioning the Human Resources
                        function in an organization. This transformation not
                        only incorporates people, processes and policies, but
                        also includes a very strong focus on the technology that
                        supports them. In many organizations, the underlying
                        digital HR technology is most critical to achieving HR
                        transformation goals.
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <Row justify="center" gutter={16}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 18 }}
                        lg={{ span: 18 }}
                      >
                        <div className="process-steps">
                          <Tabs defaultActiveKey="1" centered>
                            <TabPane tab="1. Design" key="1">
                              <p>
                                We can help you choose the right solutions and
                                technology to build HR processes that meet your
                                transformation goals.
                              </p>
                              <p>
                                Based on a 3 step approach “Understand – Rethink
                                – Design” we scan your current situation, align
                                on pain points, needs and opportunities and
                                reflect together about the user experience and
                                processes, resulting in a Roadmap and a HRIS
                                architecture.
                              </p>
                            </TabPane>
                            <TabPane tab="2. Implementation" key="2">
                              <p>
                                Our consultants are experts in implementing top
                                HR technology, including SAP HCM, Peoplesoft,
                                Successfactor and Workday and more.
                              </p>
                              <p>
                                Based on a clear implementation methodology, we
                                guide you through the implementation and testing
                                cycles, while considering the overall Roadmap
                                and user experience.
                              </p>
                            </TabPane>
                            <TabPane tab="3. Support" key="3">
                              <p>
                                From simple system support over smart advice on
                                how to deal with software and processes to full
                                business process outsourcing of your HR
                                department, including payroll. We offer support
                                tailored to your needs.
                              </p>
                            </TabPane>
                            <TabPane tab="4. Enhancement" key="4">
                              <p>
                                Mcbitss can help you select the right
                                enhancements to align your HR technology
                                landscape to your organization’s needs.
                              </p>
                              <ul>
                                <li>
                                  Integration capabilities with 3rd party
                                  software.
                                </li>
                                <li>
                                  Tailor-made apps for business-specific
                                  processes or tailored to your unique culture.
                                </li>
                              </ul>
                            </TabPane>
                          </Tabs>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
          {/* <!--contact-us-page ends--> */}
        </Layout>
      </React.Fragment>
    );
  }
}

export default StakeHolders;
