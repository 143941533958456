import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import Routes from "./Screens/Routes";
import store from "../src/store";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "antd/dist/antd.css";
import "./style/styles.scss";

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);
